.ImageHidden{
  min-width: 90%;
  min-width: 50%;
  justify-content: center;
  margin-bottom: 10%;
  margin-bottom: 10%;
}

.Container{
  position: relative;
}

.ItemTextRight{
  position: absolute;
  height: 99.4%;
  width: 10%;
  right: 0px;
  background-color: #00758d;
  transition: all 0.1s linear;
}

.ItemTextOpenRight{
  position: absolute;
  height: 99.4%;
  width: 50%;
  right: 0px;
  background-color: #00758d;
  transition: all 0.1s linear;
}

.TextOpen{
  position: absolute;
  margin-left: 15%;
  margin-right: 15%;
  top: 40%;
  font-weight: bold;
  color: #1DCAD3;
}

.ItemIconRight{
  position: absolute;
  height: 100%;
  right: 7%;
  top: 47%;
}

.ItemIconOpenRight{
  position: absolute;
  height: 100%;
  right: 47%;
  top: 47%;
}

.ItemTitleRight{
  position: absolute;
  right: 45%;
  top: 50%;
  color: white;
  font-weight: bold;
}

.ItemTitleOpenRight{
  position: absolute;
  height: 100%;
  right: 67%;
  top: 50%;
  color: white;
  font-weight: bold;
}

.ItemTextLeft{
  position: absolute;
  height: 99.4%;
  width: 10%;
  left: 0px;
  background-color: #00758d;
  transition: all 0.1s linear;
}

.ItemTextOpenLeft{
  position: absolute;
  height: 99.4%;
  width: 50%;
  left: 0px;
  background-color: #00758d;
  transition: all 0.1s linear;
}

.ItemIconLeft{
  position: absolute;
  height: 100%;
  left: 7%;
  top: 47%;
}

.ItemIconOpenLeft{
  position: absolute;
  height: 100%;
  left: 47%;
  top: 47%;
}

.ItemTitleLeft{
  position: absolute;
  right: 40%;
  top: 50%;
  color: white;
  font-weight: bold;
}

.ItemTitleOpenLeft{
  position: absolute;
  height: 100%;
  left: 67%;
  top: 50%;
  color: white;
  font-weight: bold;
}

.HiddenImage{
  max-width: 100%;
  height: auto;
}

.ButtonDownHiddenImage{
  margin-top: 30px;
  margin-left: 20px;
}

.ButtonUpHiddenImage{
  margin-top: 30px;
  margin-right: 20px;
}

@media screen and (min-width : 1281px) and  (max-width : 1366px) {
  .Content{
    min-width: 500px;
  }

  .Content .TextoCorrido{
    max-width: 500px;
  }
  
  .ImageContainer{
    max-width: 300px;
    min-width: 300px;
    max-height: 300px;
    min-height: 300px;
    margin-right: 80px;
  }

  .CardImage {
    max-height: 300px;
    max-width: 300px;
  }

  .HiddenImage{
  }

  .ItemTextLeft{
    height: 99.3%;
  }

  .ItemTextOpenLeft{
    height: 99.3%;
  }

  .ItemTextRight{
    height: 99.3%;
  }

  .ItemTextOpenRight{
    height: 99.3%;
  }

  .ButtonDownHiddenImage{
    margin-left: 10px;
  }
  
  .ButtonUpHiddenImage{
    margin-right: 10px;
  }
}

@media screen and (min-width : 761px) and  (max-width : 1280px) {
  .Content{
    min-width: 390px;
  }

  .Content .TextoCorrido{
    max-width: 390px;
  }
  
  .ImageContainer{
    max-width: 200px;
    min-width: 200px;
    max-height: 200px;
    min-height: 200px;
    margin-right: 80px;
  }

  .CardImage {
    max-height: 200px;
    max-width: 200px;
  }

  .HiddenImage{
  }

  .ItemTextLeft{
    height: 99.2%;
  }

  .ItemTextOpenLeft{
    height: 99.2%;
  }

  .ItemTextRight{
    height: 99.2%;
  }

  .ItemTextOpenRight{
    height: 99.2%;
  }

  .ButtonDownHiddenImage{
    margin-left: 10px;
  }
  
  .ButtonUpHiddenImage{
    margin-right: 10px;
  }
}

@media screen and (max-width : 760px) {
  .Content{
    min-width: 200px;
  }

  .Content .TextoCorrido{
    max-width: 200px;
  }
  
  .ImageContainer{
    max-width: 200px;
    min-width: 200px;
    max-height: 200px;
    min-height: 200px;
    margin-right: 80px;
  }

  .CardImage {
    max-height: 200px;
    max-width: 200px;
  }

  .HiddenImage{
  }

  .ItemTextLeft{
    height: 99.2%;
  }

  .ItemTextOpenLeft{
    height: 99.2%;
  }

  .ItemTextRight{
    height: 99.2%;
  }

  .ItemTextOpenRight{
    height: 99.2%;
  }
}

