.CaminoRecorrido {
    overflow-x: hidden;
    font-family: 'Work Sans', sans-serif;
    background-color: #f9f7f1;
}

.centerElement {
    position: relative;
}

@media screen and (max-width : 760px) {
    .elementHiddenMobile {
        display: none;
    };
};
  
  /* Size dependant RULES */

.ThemeColor{
    color: #1DCAD3;
}

.CaminoRecorrido .topTitle{ 
  margin-bottom: -135px; 
}

.WideButton:focus{
    outline: black auto 50px;
}

  @media screen and (min-width : 1367px){
    .CaminoRecorrido .leftArrowGraph{
      margin-right: 50px;
      margin-top: 35px;
    }

    .CaminoRecorrido .rightArrowGraph{
      margin-left: 50px;
      margin-top: 35px;
    }
  }
  
  @media screen and (min-width : 1281px) and  (max-width : 1366px) {
    .CaminoRecorrido .leftArrowGraph{
      margin-right: 35px;
      margin-top: 20px;
    }

    .CaminoRecorrido .rightArrowGraph{
      margin-left: 30px;
      margin-top: 20px;
    }

  }
  
  @media screen and (min-width : 761px) and  (max-width : 1280px) {
    .CaminoRecorrido .leftArrowGraph{
      margin-right: 10px;
      margin-top: 5px;
    }

    .CaminoRecorrido .rightArrowGraph{
      margin-top: 5px;
    }

  }

  @media (min-width: 760px) {
    #contactoBlock {
      min-height: auto;
      height: 53vh;
    }
  }

  .CaminoRecorrido .SecondaryColor{
    background-color: #00758D;
    margin-bottom: 250px;
  }

  .CaminoRecorrido .PrimaryColor{
    background-color: #1DCAD3;
    margin-bottom: 250px;
  }
  
  /* #endregion */
  