.Page-Title{
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
}

br {
    display: block;
    content: "";
    margin: 10px 0px 0;
}

.Numeration{
    padding-bottom: 2%;
}

.ThemeColor{
    color: #1DCAD3;
}

.SecondaryThemeColor{
    color: #00758d;
}

.AlternativeThemeColor{
    color: white;
}
