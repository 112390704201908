.pageIconSlider{
  min-height: 550px;
  overflow: hidden;
  user-select: none;
}
/* #endregion */
/* #region Card */
/* Card */
.pageIconSlider .card {
  transition: all 0.3s linear;
  cursor: pointer;
  padding-left: 100px;
  padding-right: 100px;
  max-width: 2200px;
  text-align: left;
  margin: 0 auto;
}

.pageIconSlider .card img {
  margin: 0 auto;
  display: block;
}

.pageIconSlider.card .index {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  line-height: 20px;
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  padding: 0 10px;
}
.pageIconSlider .card .price {
  margin: 0;
  padding: 10px 0;
  font-weight: bold;
}
.pageIconSlider .card .details {
  position: relative;
}
.pageIconSlider .card .features {
  list-style: none;
  padding: 0;
  margin: 0;
}
.pageIconSlider .card .features li {
  padding-left: 24px;
  margin-right: 10px;
  display: inline-block;
}
.pageIconSlider .card .features li span {
  display: none;
}

/* #endregion */
/* #region cards slider */
.pageIconSlider .cards-slider-wrapperIcon {
  display: flex;
  position: absolute;
  transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  left: -750px;
}

.pageIconSlider .cards-slider-wrapperIcon .card {
  flex: 1;
  transform: scale(0.85);
  box-shadow: none;
  transition: opacity 300ms linear, border-color 300ms linear, background-color 300ms linear, transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.pageIconSlider .cards-slider {
  position: relative;
  max-width: 226px;
  margin: 0 auto;
}

/* #endregion */


@media screen and (min-width : 1600px) and  (max-width : 1700px) {
  .pageIconSlider .cards-slider-wrapperIcon {
    left: -750px;
  }

  .pageIconSlider .card {
    padding-left: 100px;
    padding-right: 100px
  }
}

@media screen and (min-width : 1450px) and  (max-width : 1600px) {
  .pageIconSlider .cards-slider-wrapperIcon {
    left: -700px;
  }

  .pageIconSlider .card {
    padding-left: 100px;
    padding-right: 100px
  }
}

@media screen and (min-width : 1366px) and  (max-width : 1450px) {
  .pageIconSlider .cards-slider-wrapperIcon {
    left: -550px;
  }

  .pageIconSlider .card {
    padding-left: 50px;
    padding-right: 50px
  }

}

@media screen and (min-width : 1281px) and  (max-width : 1366px) {
  .pageIconSlider .cards-slider-wrapperIcon {
    left: -550px;
  }
  .pageIconSlider .page{
    min-height: 700px;
  }
  .pageIconSlider .card {
    padding-left: 50px;
    padding-right: 50px
  }
  .pageIconSlider{
    min-height: 450px;
  }
}

@media screen and (min-width : 1100px) and  (max-width : 1281px) {
  .pageIconSlider .cards-slider-wrapperIcon {
    left: -450px;
  }
  .pageIconSlider .page{
    min-height: 700px;
  }
  .pageIconSlider .card {
    padding-left: 50px;
    padding-right: 50px
  }
  .pageIconSlider{
    min-height: 450px;
  }
}

@media screen and (min-width : 1000px) and  (max-width : 1100px) {
  .pageIconSlider .cards-slider-wrapperIcon {
    left: -400px;
  }
  .pageIconSlider .page{
    min-height: 700px;
  }
  .pageIconSlider .card {
    padding-left: 50px;
    padding-right: 50px
  }
  .pageIconSlider{
    min-height: 400px;
  }
}

@media screen and (min-width : 761px) and  (max-width : 1000px) {
  .pageIconSlider .cards-slider-wrapperIcon {
    left: -250px;
  }
  .pageIconSlider .page{
    min-height: 900px;
  }
  .pageIconSlider .card {
    padding-left: 0;
    padding-right: 0
  }
  .pageIconSlider{
    min-height: 400px;
  }
}

@media screen and (max-width : 760px) {
  .pageIconSlider .cards-slider-wrapperIcon {
    left: -120px;
  }
  .pageIconSlider .page{
    min-height: 900px;
  }
  .pageIconSlider .card {
    padding: 0;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
  }
}


