.UnPocoDeNosotros {
    overflow-x: hidden;
    font-family: 'Work Sans', sans-serif;
    background-color: #f9f7f1;
}

.UnPocoDeNosotros .container{
  position: relative;
  text-align: left;
}

.UnPocoDeNosotros .sieteCosasText{
  margin-left: 10%;
  margin-right: 10%;
}

.sieteCosasText br{
  margin: 0;
  padding: 0;
}

.UnPocoDeNosotros .fullViewContainer{
  height: 100%;
}

.UnPocoDeNosotros .almostFullView{
  min-height: 900px;
}

.UnPocoDeNosotros .whiteContainerTop{
  position: absolute;
  margin-left: 5%;
  margin-right: 5%;
  top: 75%;
  min-width: 90%;
  height: auto;
  background-color: white;
  padding-top: 4%;
}

/* Bottom container styles */

.UnPocoDeNosotros .bottomTitle {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.UnPocoDeNosotros .sieteCosasContainer{
  position: absolute;
  top: 60%;
  left: 50%;
  height: 70%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.UnPocoDeNosotros .iconSieteCosas{
  width: auto;
  min-width: 150px;
  min-height: 150px;
  max-height: 150px;
  margin-right: 1%;
  margin-left: 1%;
}

.UnPocoDeNosotros .iconSieteCosasContainer{
  width: auto;
  min-width: 150px;
  min-height: 170px;
  max-height: 170px;
  margin-right: 1%;
  margin-left: 1%;
}

.UnPocoDeNosotros .textSectionSieteCosas{
  margin-top: 5%;
}

.UnPocoDeNosotros .containerArrow{
  position: relative;
  text-align: left;
  min-width: 100%;
}

.UnPocoDeNosotros .centeredArrow{
  position: absolute;
  left: 44%;
  width: 100%;
}

/*  */

.UnPocoDeNosotros .emptyCompensator{
  height: 1300px;
}

.UnPocoDeNosotros .fullView{
  height: 100vh;
  width: auto;
  min-width: 100%;
  max-width: 100%;
}

.UnPocoDeNosotros .fulldynamicView{
  height: 30vh;
  width: auto;
  min-width: 100%;
  max-width: 100%;
}

.UnPocoDeNosotros .customBottomSection{
  height: 50vh;
  width: auto;
  min-width: 100%;
  max-width: 100%;
}

.UnPocoDeNosotros .customViewBannerImage{
  height: auto;
  width: 100%;
  min-width: 100%;
}

.UnPocoDeNosotros .customViewBottomImage{
  height: 95vh;
  min-height: 900px;
  width: auto;
  min-width: 100%;
  margin-bottom: -5px;
}

/*Secondary Elements in Containers*/

.UnPocoDeNosotros .bannerTitle{
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.UnPocoDeNosotros .impactoEconomicoIcon{
  position: absolute;
  bottom: 0%;
  right: 0;
  width: 80%;
}

.UnPocoDeNosotros .topLeft{
  position: absolute;
  top: 0%;
  left: 5%;
}

.centerElement {
    text-align: center;
}

.bottomContainer{
  position: relative;
  bottom: 0;
}

.circleData{
  position: absolute;
  top: 25%;
  right: 12%;
}

/*Styles for horizontal divisions*/

.horizontalContainer .textInLeft{
  max-width: 50%;
  width: 50%;
}

.horizontalContainer .marginLeft{
  margin-left: 5%;
}

.horizontalContainer .textInRight{
  max-width: 50%;
  width: 50%;
  padding-right: 5%;
}

.horizontalContainer .spaceInLeft{
  max-width: 50%;
  width: 50%;
  text-align: right;
  float: right;
}

.horizontalContainer .imgLeft{
  max-width: 70%;
}

.horizontalContainer .spaceInRight{
  max-width: 50%;
  width: 50%;
}

.horizontalContainer .imgRight{
  max-width: 70%;
}

.horizontalContainer .floatRight{
  float: right;
}

.horizontalContainer .textRight{
  clear: right;
}

.horizontalContainer .imageInLeft{
  max-width: 50%;
  width: 50%;
  text-align: right;
  float: right;
}

.horizontalContainer{
  display: inline-flex;
  width: 100%;
  margin-bottom: 5%;
}

.horizontalContainer .putToMiddleText{
  margin-top: 10%;
  margin-left: 10%;
}


/*UnPocoDeNosotros a la Comunidad GRID*/

.firstImageContainer{
  position: absolute;
  top: 30%;
  width: 100%;
}

/*UnPocoDeNosotros personal*/

.horizontalContainer .arrowLeft{
  max-width: 100%;
  width: 100%;
  text-align: left;
  float: left;
  padding-left: 10%;
}

.horizontalContainer .arrowRight{
  max-width: 100%;
  width: 100%;
  text-align: right;
  float: right;
  padding-right: 10%;
}

.StandardSpacingText{
  margin-bottom: 5%;
}
.StandardHalfSpacingText{
  margin-bottom: 2.5%;
}

.StandardBoldText{
  font-weight: 600;
}

.UnPocoDeNosotros .WideButton {
  margin-bottom: 250px;
}


@media screen and (max-width : 760px) {
    .elementHiddenMobile {
        display: none;
    };
};
  
  /* Size dependant RULES */
  
  
  @media screen and (min-width : 1281px) and  (max-width : 1366px) {
    .container .dynamicSpaceTexto{
      margin-bottom: 50px;
    }

    .horizontalContainer .imgLeft{
      max-width: 60%;
    }

    .horizontalContainer .imgRight{
      max-width: 60%;
    }
  }
  
  @media screen and (min-width : 761px) and  (max-width : 1280px) {
    .container .dynamicSpaceTexto{
      margin-bottom: 60px;
    }

    .horizontalContainer .imgLeft{
      max-width: 55%;
    }

    .horizontalContainer .imgRight{
      max-width: 55%;
    }
  }
  
  @media screen and (max-width : 760px) {
    .firstImageContainer{ top: 45%; }

    .horizontalContainer .imgLeft{
      max-width: 90%;
      padding-right: 10%;
    }

    .horizontalContainer .imgRight{
      max-width: 90%;
      padding-left: 10%;
    }

    .UnPocoDeNosotros .WideButton {
      width: 400px;
      height: 64px;
    }
  }

  @media (min-width: 760px) {
    #contactoBlock {
      min-height: auto;
      height: 53vh;
    }
  }

  .UnPocoDeNosotros .SecondaryBakgroundColor{
    background-color:#00758D;
  }

  .UnPocoDeNosotros .PrimaryBackgroundColor{
    background-color: #1DCAD3;
  }

  /*ICON WITH TEXT*/

  .UnPocoDeNosotros .svg_icons{
    transform: scale(1.5);
  }

  .iconWithText{
    min-height: 400px;
    max-width: 100%
  }

  .iconWithText .imageTop{
    width: 100%;
    height: 130px;
    max-height: 130px;
    min-height: 130px;
  }

  .imageTop td{
    vertical-align: middle;
    text-align: center;
  }

  .iconWithText .titleMiddle{
    width: 100%;
    width: 100%;
    height: 20%;
    max-height: 20%;
    min-height: 20%;
  }

  .iconWithText .textBottom{
    width: 100%;
    width: 100%;
    height: 30%;
    max-height: 30%;
    min-height: 30%;
  }

  
  /* #endregion */

  @media screen and (min-width : 1530px) and  (max-width : 1600px) {
    .UnPocoDeNosotros .whiteContainerTop{
    }
    
    .UnPocoDeNosotros .emptyCompensator{
      height: 1300px;
    }
  }

  @media screen and (min-width : 1366px) and  (max-width : 1530px) {
    .UnPocoDeNosotros .whiteContainerTop{
    }
    
    .UnPocoDeNosotros .emptyCompensator{
      height: 1400px;
    }
  }
  
  @media screen and (min-width : 1281px) and  (max-width : 1366px) {
    .UnPocoDeNosotros .whiteContainerTop{
    }
    
    .UnPocoDeNosotros .emptyCompensator{
      height: 1200px;
    }
  }
  
  @media screen and (min-width : 761px) and  (max-width : 1280px) {
    .UnPocoDeNosotros .whiteContainerTop{
    }
    
    .UnPocoDeNosotros .emptyCompensator{
      height: 1000px;
    }

    .UnPocoDeNosotros .iconSieteCosas{
      width: auto;
      max-width: 100px;
      min-width: 100px;
      min-height: 100px;
      max-height: 100px;
      margin-right: 0.5%;
      margin-left: 0.5%;
    }

    .UnPocoDeNosotros .iconSieteCosasContainer{
      width: auto;
      max-width: 100px;
      min-width: 100px;
      min-height: 120px;
      max-height: 120px;
      margin-right: 0.5%;
      margin-left: 0.5%;
    }

    .UnPocoDeNosotros .imageSieteCosas{
      max-width: 80px;
      max-height: 70px;
    }
  }
  
  @media screen and (max-width : 760px) {
    .UnPocoDeNosotros .whiteContainerTop{
    }
    
    .UnPocoDeNosotros .emptyCompensator{
      height: 1650px;
    }
    
    .UnPocoDeNosotros .iconSieteCosas{
      width: auto;
      max-width: 75px;
      min-width: 75px;
      min-height: 75px;
      max-height: 75px;
      margin-right: 0.5%;
      margin-left: 0.5%;
    }

    .UnPocoDeNosotros .iconSieteCosasContainer{
      width: auto;
      max-width: 75px;
      min-width: 75px;
      min-height: 95px;
      max-height: 95px;
      margin-right: 0.5%;
      margin-left: 0.5%;
    }

    .UnPocoDeNosotros .imageSieteCosas{
      max-height: 100%;
      max-width: 70px;
      max-height: 70px;
    }
  }
  