.ThemeColor{
    color: #1DCAD3;
}
  
.SecondaryColor{
    color: #00758D;
}
  
.AlternativeColor{
    color: white;
}

.WideButton {
    color: #ffffff;
    border: none;
    letter-spacing: 1.2;
    width: 500px;
    height: 80px;
    cursor: pointer;
}

.WideButton:focus{
    outline: black auto 50px;
}


@media screen and (min-width : 1367px){
    .Inicio { font-size: 240px;}
  
    .Datos{ font-size: 90px;}
  
    .Titulos{ font-size: 56px; line-height:56px;}

    .TitulosGrande{ font-size: 70px; line-height:56px;}
  
    .Informacion{ font-size: 30px;}
  
    .TextoCorrido{ font-size: 24px;}
  
    .TitulosExtras{ font-size: 20px;}

    .IconArrowButton{height: 50px;}

    .BigArrowButton{height: 60px;}

    .NumeroTitulo{font-size: 150px; font-weight: 500;}

}
  
@media screen and (min-width : 1281px) and  (max-width : 1366px) {
    .Inicio { font-size: 192px;}
  
    .Datos{ font-size: 72px;}
  
    .Titulos{ font-size: 45px; line-height:45px;}

    .TitulosGrande{ font-size: 56px; line-height:56px;}
  
    .Informacion{ font-size: 24px;}
  
    .TextoCorrido{ font-size: 19px;}
  
    .TitulosExtras{ font-size: 16px;}

    .IconArrowButton{height: 40px;}

    .BigArrowButton{height: 48px;}

    .WideButton {
        width: 400px;
        height: 64px;
    }

    .NumeroTitulo{font-size: 120px; font-weight: 500;}

}
  
@media screen and (min-width : 761px) and  (max-width : 1280px) {
    .Inicio { font-size: 168px;}
  
    .Datos{ font-size: 63px;}
  
    .Titulos{ font-size: 39px; line-height:39px;}

    .TitulosGrande{ font-size: 49px; line-height:56px;}
  
    .Informacion{ font-size: 21px;}
  
    .TextoCorrido{ font-size: 17px;}
  
    .TitulosExtras{ font-size: 14px;}

    .IconArrowButton{height: 35px;}

    .BigArrowButton{height: 42px;}

    .WideButton {
        width: 350px;
        height: 56px;
    }

    .NumeroTitulo{font-size: 105px; font-weight: 500;}

}
  
@media screen and (max-width : 760px) {
    .Inicio { font-size: 120px;}
  
    .Datos{ font-size: 70px;}
  
    .Titulos{ font-size: 45px; line-height:45px;}

    .TitulosGrande{ font-size: 56px; line-height:56px;}
  
    .Informacion{ font-size: 25px;}
  
    .TextoCorrido{ font-size: 30px;}
  
    .TitulosExtras{ font-size: 20px;}

    .IconArrowButton{height: 40px;}

    .BigArrowButton{height: 48px;}

    .WideButton {
        width: 400px;
        height: 64px;
    }

    .NumeroTitulo{font-size: 75px; font-weight: 500;}

}