.card-down{
    padding-top: 150px;
}

.Title-Card{
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0px;
}

p {
    margin: 20px 0;
}

.Details-Card{
    margin-top: 0px;
}


.Image {
    width: 400px;
    height: 407px;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

  
@media screen and (min-width : 1281px) and  (max-width : 1366px) {
    .Image {
        width: 385px;
        height: 392px;
    }
}

@media screen and (min-width : 761px) and  (max-width : 1280px) {
    .Image {
        width: 378px;
        height: 385px;
    }
}

@media screen and (max-width : 760px) {
    .Image {
        width: 383px;
        height: 404px;
    }
}