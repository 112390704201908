.pageIconSlider .card-down{
    padding-top: 150px;
}


.pageIconSlider .Title-Card{
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0px;
}

p {
    margin: 20px 0;
}

.pageIconSlider .Details-Card{
    margin-top: 0px;
}

.pageIconSlider .Image {
    width: 400px;
    height: 400px;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.pageIconSlider .card .container{
    position: relative;
}

.pageIconSlider .container .iconleft{
    position: absolute;
    bottom: -1px;
    left: -50px;
}

.pageIconSlider .container .iconCenter{
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translate(-50%);
}

.pageIconSlider .container .iconRight{
    position: absolute;
    bottom: -1px;
    right: -50px;
}


@media screen and (min-width : 1600px) and  (max-width : 1700px) {
    .pageIconSlider .container .iconSlider{
        width: auto;
        max-height:100px;
    }
}

@media screen and (min-width : 1450px) and  (max-width : 1600px) {
    .pageIconSlider .Image {
        width: 350px;
        height: 350px;
    }
    .pageIconSlider .container .iconSlider{
        width: auto;
        max-height:90px;
    }
}

@media screen and (min-width : 1366px) and  (max-width : 1450px) {
    .pageIconSlider .Image {
        width: 350px;
        height: 350px;
    }
    .pageIconSlider .container .iconSlider{
        width: auto;
        max-height:80px;
    }
}

@media screen and (min-width : 1281px) and  (max-width : 1366px) {
    .pageIconSlider .Image {
        width: 350px;
        height: 350px;
    }
    .pageIconSlider .container .iconSlider{
        width: auto;
        max-height:80px;
    }
}

@media screen and (min-width : 1100px) and  (max-width : 1281px) {
    .pageIconSlider .Image {
        width: 300px;
        height: 300px;
    }
    .pageIconSlider .container .iconSlider{
        width: auto;
        max-height:65px;
    }
}

@media screen and (min-width : 1000px) and  (max-width : 1100px) {
    .pageIconSlider .Image {
        width: 250px;
        height: 250px;
    }
    .pageIconSlider .container .iconSlider{
        width: auto;
        max-height:65px;
    }
}

@media screen and (min-width : 761px) and  (max-width : 1000px) {
    .pageIconSlider .Image {
        width: 250px;
        height: 250px;
    }
    .pageIconSlider .container .iconSlider{
        width: auto;
        max-height:65px;
    }
}

@media screen and (max-width : 760px) {
    .pageIconSlider .Image {
        width: 350px;
        height: 350px;
    }
    .pageIconSlider .container .iconSlider{
        width: auto;
        max-height:90px;
    }
}