/* #endregion */
/* #region Card */
/* Card */
.Card {
  transition: all 0.3s linear;
  cursor: pointer;
  padding: 10px;
  height: 50%;
}

.Content{
  min-width: 600px;
}

.Content .TitulosExtras{
  color: #1DCAD3;
  margin-bottom: 10%;
}

.Content .Informacion{
  font-weight: 500;
  margin-bottom: 10%;
}

.Content .TextoCorrido{
  max-width: 500px;
}

.CardImage{
  vertical-align: middle;
  display: inline-block;
}

.ImagePartner{
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.ImageContainer{
  max-width: 400px;
  min-width: 400px;
  max-height: 400px;
  min-height: 400px;
  margin-right: 100px;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (min-width : 1281px) and  (max-width : 1366px) {
  .Content{
    min-width: 500px;
  }

  .Content .TextoCorrido{
    max-width: 500px;
  }
  
  .ImageContainer{
    max-width: 300px;
    min-width: 300px;
    max-height: 300px;
    min-height: 300px;
    margin-right: 80px;
  }

  .CardImage {
    max-height: 300px;
    max-width: 300px;
  }
}

@media screen and (min-width : 761px) and  (max-width : 1280px) {
  .Content{
    min-width: 390px;
  }

  .Content .TextoCorrido{
    max-width: 390px;
  }
  
  .ImageContainer{
    max-width: 200px;
    min-width: 200px;
    max-height: 200px;
    min-height: 200px;
    margin-right: 80px;
  }

  .CardImage {
    max-height: 200px;
    max-width: 200px;
  }
}

@media screen and (max-width : 760px) {
  .Content{
    min-width: 200px;
  }

  .Content .TextoCorrido{
    max-width: 200px;
  }
  
  .ImageContainer{
    max-width: 200px;
    min-width: 200px;
    max-height: 200px;
    min-height: 200px;
    margin-right: 80px;
  }

  .CardImage {
    max-height: 200px;
    max-width: 200px;
  }
}

.MuiTouchRipple-root{
  padding: 0 !important;
}

.MuiIconButton-label{
  padding: 0 !important;
}

