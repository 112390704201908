.NuestroEquipo {
    overflow-x: hidden;
    font-family: 'Work Sans', sans-serif;
    background-color: #f9f7f1;
}

.NuestroEquipo .StandardSpacingText{
  margin-bottom: 5%;
}

.NuestroEquipo .StandardBoldText{
  font-weight: 600;
}

.NuestroEquipo .MediumBoldText{
  font-weight: 500;
}

.NuestroEquipo .StandardWeightText{
  font-weight: 200;
}

.NuestroEquipo .ImageWithHiddenText{
  position: relative;
  text-align: center;
}

.NuestroEquipo .cardDown{
  margin-top: 25%;
}

.NuestroEquipo .imageContainer{
  
}

.imageContainer img{
  max-width: 100%;
}

.NuestroEquipo .titleContainer{
    position: absolute;
    bottom: 5%;
    left: -4.5%;
    text-align: left;
}

.NuestroEquipo .titleContainerTop{
  position: absolute;
  top: 20%;
  left: -4.5%;
  text-align: left;
}

.NuestroEquipo .textContainer{
  position: absolute;
  top: 50%;
  text-align: left;
  max-width: 80%;
  left: 10%;
}

.NuestroEquipo .generalText{
  margin-bottom: -20px;
  padding-bottom: 0;
}

.NuestroEquipo .textContainer p{
  margin-bottom: 0;
}

/**/

.NuestroEquipo .equipoContainer{
  margin-left: 7%;
  margin-right: 7%;
  margin-top: 5%;
}

/**/

.NuestroEquipo .GiantTextDivision
{
  width: 100%;
  margin-bottom: 10%;
  margin-top: 10%;
}

.GiantTextDivision .giantText{
  width: 100%;
  text-align: center;
}

.GiantTextDivision .openImage{
  width: 21%;
  text-align: right;
}

.GiantTextDivision .closeImage{
  width: 85%;
  text-align: right;
}

.GiantTextDivision img{
  max-width: 100px;
}

/**/

.NuestroEquipo .coordContainer{
  position: relative;
  background-color: white;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
  min-height: 1100px;
  max-height: 1100px;
}

.NuestroEquipo .coordImageContainer{
  max-width: 90%;
  padding-bottom: 5%;
  margin-left: 10%;
  margin-right: 5%;
}

.NuestroEquipo .coordTitle{
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
}

.NuestroEquipo .imageWithText{
  min-width: 100%;
  width: 100%;
}

.NuestroEquipo .imageContainerHorizontal{
  max-width: 35%;
  width: 35%;
  margin: 0;
  float: left;
}

.NuestroEquipo .textContainerHorizontal{
  width: 50%;
  min-width: 50%;
  float: left;
  padding-left: 5%;
  padding-right: 5%;
}

.imageContainerHorizontal img{
  max-width: 90%;
}

.NuestroEquipo .imgDownSmall{
  margin-top: 20%;
}

.NuestroEquipo .smallContainerSpacing{
  padding-top: 5%;
}

.NuestroEquipo .arrowLeftCoord{
  position: absolute;
  left: -25px;
  top: 50%;
}

.NuestroEquipo .arrowRightCoord{
  position: absolute;
  right: -25px;
  top: 50%;
}
  
/* Size dependant RULES */

.ThemeColor{
    color: #1DCAD3;
}

.NuestroEquipo .topTitle{ 
  margin-bottom: -135px; 
}

.WideButton:focus{
    outline: black auto 50px;
}

@media screen and (min-width : 1500px) and  (max-width : 1700px){
  .GiantTextDivision .openImage{
    width: 16%;
  }
  
  .GiantTextDivision .closeImage{
    width:91%;
  }
  
  .GiantTextDivision img{
    max-width: 100px;
  }  

  .NuestroEquipo .coordContainer{
    min-height: 1000px;
    max-height: 1000px;
  }
}

@media screen and (min-width : 1367px) and  (max-width : 1500px){
  .NuestroEquipo .titleContainer{
    left: -10%;
  }

  .NuestroEquipo .titleContainerTop{
    top: 10%;
    left: -10%;
  }
  
  .NuestroEquipo .textContainer{
    top: 45%;
    max-width: 80%;
  }

  .GiantTextDivision .openImage{
    width: 10%;
  }
  
  .GiantTextDivision .closeImage{
    width: 95%;
  }
  
  .GiantTextDivision img{
    max-width: 90px;
  }  

  .NuestroEquipo .coordContainer{
    min-height: 900px;
    max-height: 900px;
  }
}
  
@media screen and (min-width : 1281px) and  (max-width : 1366px) {
  .NuestroEquipo .titleContainer{
    left: -10%;
  }

  .NuestroEquipo .titleContainerTop{
    top: 10%;
    left: -10%;
  }
  
  .NuestroEquipo .textContainer{
    top: 45%;
    max-width: 80%;
  }

  .GiantTextDivision .openImage{
    width: 17%;
  }
  
  .GiantTextDivision .closeImage{
    width: 90%;
  }
  
  .GiantTextDivision img{
    max-width: 80px;
  }  

  .NuestroEquipo .arrowLeftCoord{
    left: -20px;
  }
  
  .NuestroEquipo .arrowRightCoord{
    right: -20px;
  }

  .NuestroEquipo .coordContainer{
    min-height: 800px;
    max-height: 800px;
  }
}
  
@media screen and (min-width : 1000px) and  (max-width : 1280px) {
  .NuestroEquipo .titleContainer{
    left: -10%;
  }

  .NuestroEquipo .titleContainerTop{
    top: 5%;
    left: -10%;
  }
  
  .NuestroEquipo .textContainer{
    top: 45%;
    max-width: 80%;
  }

  .GiantTextDivision .openImage{
    width: 19%;
  }
  
  .GiantTextDivision .closeImage{
    width: 89%;
  }
  
  .GiantTextDivision img{
    max-width: 70px;
  } 

  .NuestroEquipo .arrowLeftCoord{
    left: -17.5px;
  }
  
  .NuestroEquipo .arrowRightCoord{
    right: -17.5px;
  }

  .NuestroEquipo .coordContainer{
    min-height: 750px;
    max-height: 750px;
  }
}

@media screen and (min-width : 767px) and  (max-width : 1000px) {
  .NuestroEquipo .titleContainer{
    left: -10%;
  }

  .NuestroEquipo .titleContainerTop{
    top: -20%;
    left: -10%;
  }
  
  .NuestroEquipo .textContainer{
    top: 30%;
    max-width: 80%;
    left: 5%;
  }

  .NuestroEquipo .blackLittle{
    color: black;
  }

  .imageContainer img{
    max-width: 120%;
  }

  .GiantTextDivision .openImage{
    width: 11%;
  }
  
  .GiantTextDivision .closeImage{
    width: 98%;
  }
  
  .GiantTextDivision img{
    max-width: 70px;
  } 

  .NuestroEquipo .arrowLeftCoord{
    left: -17.5px;
  }
  
  .NuestroEquipo .arrowRightCoord{
    right: -17.5px;
  }

  .NuestroEquipo .coordContainer{
    min-height: 650px;
    max-height: 650px;
  }
}

@media (max-width: 766px) {
  .NuestroEquipo .titleContainer{
    left: -10%;
  }

  .NuestroEquipo .titleContainerTop{
    top: 20%;
    left: -10%;
  }
  
  .NuestroEquipo .textContainer{
    top: 45%;
    max-width: 80%;
    left: 5%;
  }

  .NuestroEquipo .arrowLeftCoord{
    left: -20px;
  }
  
  .NuestroEquipo .arrowRightCoord{
    right: -20px;
  }
  
  .NuestroEquipo .coordContainer{
    min-height: 100%;
    max-height: 100%;
  }
}
  
  /* #endregion */
  