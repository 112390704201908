.ComoTrabajamos {
    overflow-x: hidden;
    font-family: 'Work Sans', sans-serif;
    background-color: #f9f7f1;
}

.centerElement {
    position: relative;
}

.ComoTrabajamos .containerHiddenTextImage{
  margin-left: 5%;
  margin-right: 5%;
}

@media screen and (max-width : 760px) {
    .elementHiddenMobile {
        display: none;
    };
};
  
  /* Size dependant RULES */
  
.ThemeColor{
    color: #1DCAD3;
}

@media (min-width: 760px) {
    #contactoBlock {
      min-height: auto;
      height: 53vh;
    }
}

.ComoTrabajamos .SecondaryColor{
    background-color: #00758D;
    color: white;
    margin-bottom: 250px;
}

.ComoTrabajamos .PrimaryColor{
    background-color: #1DCAD3;
}
  
  /* #endregion */
  