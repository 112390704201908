.Impacto {
    overflow-x: hidden;
    font-family: 'Work Sans', sans-serif;
    background-color: #f9f7f1;
}

.Impacto .container{
  position: relative;
  text-align: left;
}

.Impacto .containerBanner{
  position: relative;
  height: 100;
}

.Impacto .fullView{
  height: 100vh;
  min-height: 1000px;
  width: auto;
  min-width: 100%;
  max-width: 100%;
}

.Impacto .halfView{
  min-height: 500px;
}

.Impacto .fulldynamicView{
  height: 30vh;
  width: auto;
  min-width: 100%;
  max-width: 100%;
  min-height: 500px;
}

.Impacto .customBottomSection{
  height: 50vh;
  width: auto;
  min-width: 100%;
  max-width: 100%;
}

.Impacto .customViewBannerImage{
  height: 93vh;
  width: auto;
  min-width: 100%;
}

/*Main Elements in Containers*/

.Impacto .imageBanner{
  height: 100%;
  max-height: 95vh;
  width: auto;
  min-width: 100%;
  max-width: 100%;
}

/*Secondary Elements in Containers*/

.Impacto .bannerTitle{
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.Impacto .impactoEconomicoIcon{
  position: absolute;
  bottom: 0%;
  right: 0;
  width: 80%;
}

.Impacto .topLeft{
  position: absolute;
  top: 0%;
  left: 5%;
}

.centerElement {
    position: relative;
}

.bottomContainer{
  position: relative;
  bottom: 0;
}

.circleData{
  position: absolute;
  top: 25%;
  right: 12%;
}

/*Styles for horizontal divisions*/

.horizontalContainer .textInLeft{
  max-width: 50%;
  width: 50%;
  text-align: right;
  padding-right: 5%;
}

.horizontalContainer .textInRight{
  max-width: 50%;
  width: 50%;
}

.horizontalContainer .imageInLeft{
  max-width: 50%;
  width: 50%;
  text-align: right;
  float: right;
}

.horizontalContainer .imgLeft{
  max-width: 70%;
}

.horizontalContainer .imageInRight{
  max-width: 50%;
  width: 50%;
}

.horizontalContainer .imgRight{
  max-width: 70%;
}

.horizontalContainer{
  display: inline-flex;
  width: 100%;
  margin-bottom: 5%;
}

.horizontalContainer .putToMiddleText{
  margin-top: 30%;
  margin-left: 10%;
}


/*Impacto a la Comunidad GRID*/

.firstImageContainer{
  position: absolute;
  top: 30%;
  width: 100%;
}

/*Impacto personal*/

.horizontalContainer .arrowLeft{
  max-width: 100%;
  width: 100%;
  text-align: left;
  float: left;
  padding-left: 10%;
}

.horizontalContainer .arrowRight{
  max-width: 100%;
  width: 100%;
  text-align: right;
  float: right;
  padding-right: 10%;
}

.Impacto .TextoCorrido{ 
  margin-bottom: 3%;
}

.Impacto .StandardSpacingText{
  margin-bottom: 5%;
}

.Impacto .StandardBoldText{
  font-weight: 600;
}

.Impacto .WideButton {
  margin-bottom: 250px;
}


@media screen and (max-width : 760px) {
    .elementHiddenMobile {
        display: none;
    };
};
  
  /* Size dependant RULES */
  
  @media screen and (min-width : 1281px) and  (max-width : 1366px) {
    .container .dynamicSpaceTexto{
      margin-bottom: 50px;
    }
  }
  
  @media screen and (min-width : 761px) and  (max-width : 1280px) {
    .container .dynamicSpaceTexto{
      margin-bottom: 60px;
    }
  }
  
  @media screen and (max-width : 760px) {
    .firstImageContainer{ top: 45%; }

    .horizontalContainer .imgLeft{
      max-width: 90%;
      padding-right: 10%;
    }

    .horizontalContainer .imgRight{
      max-width: 90%;
      padding-left: 10%;
    }
  }

  @media (min-width: 760px) {
    #contactoBlock {
      min-height: auto;
      height: 53vh;
    }
  }

  .Impacto .SecondaryBakgroundColor{
    background-color:#00758D;
  }

  .Impacto .PrimaryBackgroundColor{
    background-color: #1DCAD3;
  }
  
  /* #endregion */

  @media screen and (min-width : 1600px) and  (max-width : 1700px) {
    .horizontalContainer .arrowLeft{
      padding-left: 5%;
    }

    .horizontalContainer .arrowRight{
      padding-right: 5%;
    }
  }
  
  @media screen and (min-width : 1366px) and  (max-width : 1600px) {
    .horizontalContainer .arrowLeft{
      padding-left: 5%;
    }

    .horizontalContainer .arrowRight{
      padding-right: 5%;
    }
  }
  
  @media screen and (min-width : 1281px) and  (max-width : 1366px) {
    .horizontalContainer .arrowLeft{
      padding-left: 10%;
    }

    .horizontalContainer .arrowRight{
      padding-right: 10%;
    }
  }
  
  @media screen and (min-width : 761px) and  (max-width : 1280px) {
    .horizontalContainer .arrowLeft{
      padding-left: 10%;
    }

    .horizontalContainer .arrowRight{
      padding-right: 10%;
    }
  }
  
  @media screen and (max-width : 760px) {
    .horizontalContainer .arrowLeft{
      padding-left: 10%;
    }

    .horizontalContainer .arrowRight{
      padding-right: 10%;
    }
  }

  @media screen and (min-width : 1650px) and  (max-width : 1700px) {
    .circleData{
      top: 33% !important;
      right: 11% !important;
    }
  }

  @media screen and (min-width : 1600px) and  (max-width : 1650px) {
    .circleData{
      top: 35% !important;
      right: 11% !important;
    }
  }
  
  @media screen and (min-width : 1550px) and  (max-width : 1600px) {
    .circleData{
      top: 37% !important;
      right: 10% !important;
    }
  }

  @media screen and (min-width : 1500px) and  (max-width : 1550px) {
    .circleData{
      top: 39% !important;
      right: 10% !important;
    }
  }

  @media screen and (min-width : 1450px) and  (max-width : 1500px) {
    .circleData{
      top: 40.5% !important;
      right: 10% !important;
    }
  }

  @media screen and (min-width : 1366px) and  (max-width : 1450px) {
    .circleData{
      top: 42% !important;
      right: 10% !important;
    }
  }

  @media screen and (min-width : 1366px) and  (max-width : 1400px) {
    .circleData{
      top: 44% !important;
      right: 10% !important;
    }
  }

  @media screen and (min-width : 1300px) and  (max-width : 1366px) {
    .circleData{
      top: 46% !important;
      right: 11% !important;
    }
  }

  @media screen and (min-width : 1280px) and  (max-width : 1300px) {
    .circleData{
      top: 49% !important;
      right: 10.5% !important;
    }
  }

  @media screen and (min-width : 1250px) and  (max-width : 1280px) {
    .circleData{
      top: 49% !important;
      right: 11% !important;
    }
  }

  @media screen and (min-width : 1200px) and  (max-width : 1250px) {
    .circleData{
      top: 51% !important;
      right: 11% !important;
    }
  }

  @media screen and (min-width : 1150px) and  (max-width : 1200px) {
    .circleData{
      top: 52.5% !important;
      right: 11% !important;
    }
  }

  @media screen and (min-width : 1100px) and  (max-width : 1150px) {
    .circleData{
      top: 54.5% !important;
      right: 11% !important;
    }
  }

  @media screen and (min-width : 1050px) and  (max-width : 1100px) {
    .circleData{
      top: 57% !important;
      right: 11% !important;
    }
  }

  @media screen and (min-width : 1000px) and  (max-width : 1050px) {
    .circleData{
      top: 58% !important;
      right: 9.5% !important;
    }
  }

  @media screen and (min-width : 761px) and  (max-width : 1000px) {
    .circleData{
      top: 30% !important;
      right: 5% !important;
      color: black !important;
    }
  }

  @media screen and (max-width : 760px) {
    .circleData{
      top: 45% !important;
      right: 5% !important;
      color: black !important;
    }
  }