.Graph-Image {
    max-width:900px; height:auto;
    margin-bottom: 100px;
}

.container {
    position: relative;
    text-align: center;
}

.Text-Container{
    position: relative;
    cursor: pointer;
}

.current-card{
    font-weight: bold;
}

.r1 {left: 0px;   bottom: 100px;}
.r2 {left: 92px;  bottom: 330px;}
.r3 {left: 184px; bottom: 135px;}
.r4 {left: 276px; bottom: 410px;}
.r5 {left: 368px; bottom: 175px;}
.r6 {left: 460px; bottom: 445px;}
.r7 {left: 552px; bottom: 212px;}
.r8 {left: 644px; bottom: 482px;}
.r9 {left: 736px; bottom: 250px;}
.r10{left: 828px; bottom: 520px;}
  
@media screen and (min-width : 1281px) and  (max-width : 1366px) {
    .Graph-Image {
        max-width:720px;
    }
    .r1 {left: 0px;   bottom: 100px;}
    .r2 {left: 73px;  bottom: 285px;}
    .r3 {left: 146px; bottom: 130px;}
    .r4 {left: 219px; bottom: 350px;}
    .r5 {left: 292px; bottom: 161px;}
    .r6 {left: 365px; bottom: 380px;}
    .r7 {left: 438px; bottom: 189px;}
    .r8 {left: 511px; bottom: 410px;}
    .r9 {left: 584px; bottom: 220px;}
    .r10{left: 660px; bottom: 440px;}
}

@media screen and (min-width : 761px) and  (max-width : 1280px) {
    .Graph-Image {
        max-width:590px; 
    }
    .r1 {left: 0px;   bottom: 105px;}
    .r2 {left: 59px;  bottom: 265px;}
    .r3 {left: 118px; bottom: 132px;}
    .r4 {left: 177px; bottom: 320px;}
    .r5 {left: 236px; bottom: 158px;}
    .r6 {left: 295px; bottom: 345px;}
    .r7 {left: 354px; bottom: 184px;}
    .r8 {left: 413px; bottom: 373px;}
    .r9 {left: 472px; bottom: 210px;}
    .r10{left: 531px; bottom: 398px;}
}

@media screen and (max-width : 760px) {
    .Graph-Image {
        max-width:500px; 
    }
    .r1 {left: 0px;   bottom: 100px;}
    .r2 {left: 48px;  bottom: 285px;}
    .r3 {left: 96px;  bottom: 130px;}
    .r4 {left: 144px; bottom: 340px;}
    .r5 {left: 192px; bottom: 160px;}
    .r6 {left: 240px; bottom: 370px;}
    .r7 {left: 288px; bottom: 189px;}
    .r8 {left: 336px; bottom: 398px;}
    .r9 {left: 384px; bottom: 220px;}
    .r10{left: 438px; bottom: 428px;}
}